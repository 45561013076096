import { AnchorButton, AnchorButtonProps } from '@blueprintjs/core'
import { Link, useRoute } from 'wouter'

interface Props {
  href: string // required
  wildcard?: boolean
}

/**
 * Renders an AnchorButton-styled link using a Wouter, location-aware link.
 *
 * @param wildcard If set, the "active" style matching will append `/*` to the
 * matcher, used primarily for nested routes.
 *
 * All AnchorButton props, except `active`, can be provded. `href` must be.
 *
 * Known issue: the active state doesn't trigger if `href` is home-anchored;
 * i.e. `~/some/path`.
 */
const ActiveLink: React.FC<Props & Omit<AnchorButtonProps, 'active'>> = (props) => {
  const { href, children, wildcard, ...anchorButtonProps } = props
  const [isActive] = useRoute(wildcard ? `${href}/*` : href)
  return <Link href={href} asChild>
    <AnchorButton active={isActive} {...anchorButtonProps} >{children}</AnchorButton>
  </Link>
}

export default ActiveLink
