import { Code } from '@blueprintjs/core'

import { useApiData } from 'hooks'

interface Props {
  envId: EnvId
}
const Stats: React.FC<Props> = ({ envId }) => {
  const apiKeys = useApiData('/app/getApiKeys', { envId })
  return (
    <>
      <p>To get free passkey support stats from your site, add the following code:</p>

      <p><Code>&lt;script src="https://api.snapauth.app/stats.js" data-key="{apiKeys?.publishable[0].value}" data-dedupe defer&gt;&lt;/script&gt;</Code></p>

      <p>
        This is <em>entirely opt-in</em> and will <strong>not</strong> be done automatically by our Client SDKs.
        Read the <a href="https://docs.snapauth.app/#browser-stats" target="_blank">full docs</a> for config info.
      </p>

    </>
  )
}

export default Stats
