import { Button, HTMLTable, Spinner, Tag } from '@blueprintjs/core'

import { Count, Money, Section, Time } from 'components'
import { useApiData, useApp } from 'hooks'

import PaymentMethodInfo from '../PaymentMethodInfo'

interface Props {
  orgId: OrgId
}
const Billing: React.FC<Props> = ({ orgId }) => {
  const stats = useApiData('/app/getStatsForOrg', { orgId })
  const planInfo = useApiData('/app/org/planInfo', { orgId })
  if (!stats || !planInfo) {
    return <Spinner size={20} />
  }

  // Note: the inline calculations here will break if the plan has more than
  // one tier!
  return (
    <>
      <PlanInfo orgId={orgId} />

      <Section title="Current Usage Summary">
        <HTMLTable>
          <thead>
            <tr>
              <th>Period started</th>
              <th>Period ends</th>
              <th>Bill to date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><Time unixtimeSeconds={stats.currentPeriod.startsAt} /></td>
              <td><Time unixtimeSeconds={stats.currentPeriod.endsAt} /></td>
              <td><Money amount={stats.currentPeriod.amount} /></td>
            </tr>
          </tbody>
        </HTMLTable>

        <h4>Resources</h4>
        <HTMLTable>
          <thead>
            <tr>
              <th></th>
              <th>Count</th>
              <th>Each</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Registrations</th>
              <td><Count count={stats.currentPeriod.registrationCount} /></td>
              <td><Money amount={planInfo.config.registrationTiers[0].pricePerUser} /></td>
              <td><Money amount={Money.multiply(planInfo.config.registrationTiers[0].pricePerUser, stats.currentPeriod.registrationCount)} /></td>
            </tr>
            <tr>
              <th>Active Users</th>
              <td><Count count={stats.currentPeriod.activeUserCount} /></td>
              <td><Money amount={planInfo.config.activeUserTiers[0].pricePerUser} /></td>
              <td><Money amount={Money.multiply(planInfo.config.activeUserTiers[0].pricePerUser, stats.currentPeriod.activeUserCount)} /></td>
            </tr>
          </tbody>
        </HTMLTable>

        <p><a href="https://www.snapauth.app/pricing" target="_blank">Pricing Details</a></p>
      </Section>

      <InvoiceHistory orgId={orgId} />
    </>
  )
}

const PlanInfo: React.FC<Props> = ({ orgId }) => {
  const app = useApp()
  const planInfo = useApiData('/app/org/planInfo', { orgId })
  const stats = useApiData('/app/getStatsForOrg', { orgId })
  const cards = useApiData('/app/getCards', { orgId })

  const addCard = async () => {
    const result = await app.post('/app/payment/getAddCardFlow', {
      orgId,
      // Future: let the UI indicate the success/fail urls? There are
      // definitiely secutity implications with semi-open redirects in there.
    })
    const dest = result.redirectUrl
    window.location = dest
  }

  if (!planInfo || !stats || !cards) {
    return null
  }
  if (planInfo.selected === 'trial') {
    return (
      <Section title="Plan">
        <p>
          You're currently on the free trial.
          This grants you access to all functionality, but is limited to registering {planInfo.config.registeredUserLimit} users.
        </p>

        <p>You have currently registered {stats.registrationCount} / {planInfo.config.registeredUserLimit} users.</p>

        <p><Button intent="success" icon="credit-card" type="button" onClick={addCard}>Subscribe</Button></p>

        <p><a href="https://www.snapauth.app/pricing" target="_blank">Pricing Info</a></p>
      </Section>
    )
  }
  return (
    <Section title="Plan">
      <p>You are currently subscribed to SnapAuth. <strong>Thank you!</strong></p>
      {cards.primary &&
      <p>Primary card: <PaymentMethodInfo pm={cards.primary} /></p>
      }
    </Section>
  )
}

const InvoiceHistory: React.FC<Props> = ({ orgId }) => {
  const invoices = useApiData('/app/payment/history', { orgId })
  if (!invoices) {
    return null
  }
  let inner: React.ReactElement
  if (!invoices.length) {
    inner = <p>No invoices to date</p>
  } else {
    inner = (
      <HTMLTable>
        <thead>
          <tr>
            <th>Amount</th>
            <th>Due</th>
            <th>Status</th>
            <th>Paid</th>
          </tr>
        </thead>
        <tbody>

          {invoices.map(invoice => (
            <tr key={invoice.invoiceId}>
              <td><Money amount={invoice.amount} /></td>
              <td>{invoice.dueAt && <Time unixtimeSeconds={invoice.dueAt} />}</td>
              <td><Tag round intent={invoice.isPaid ? 'success' : 'danger'}>{invoice.isPaid ? 'Paid' : 'Unpaid'}</Tag></td>
              <td>{invoice.paidAt && <Time unixtimeSeconds={invoice.paidAt} />}</td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    )
  }
  return (
    <Section title="Invoice History">
      {inner}
    </Section>
  )
}

export default Billing
