import { OverlayToaster } from '@blueprintjs/core'
import { SDK } from '@snapauth/sdk'

import { API_HOST, SDK_PUBLISHABLE_KEY } from 'config'

// WARNING: this triggers a react 17 warning.
// see https://github.com/palantir/blueprint/issues/5212
const toaster = OverlayToaster.create()
export const toast = {
  danger: (message: string) => toaster.show({ message, intent: 'danger' }),
  primary: (message: string) => toaster.show({ message, intent: 'primary' }),
  success: (message: string) => toaster.show({ message, intent: 'success' }),
  warning: (message: string) => toaster.show({ message, intent: 'warning' }),
  stick: {
    danger: (message: string) => toaster.show({ message, intent: 'danger', timeout: 0 }),
    primary: (message: string) => toaster.show({ message, intent: 'primary', timeout: 0 }),
    success: (message: string) => toaster.show({ message, intent: 'success', timeout: 0 }),
    warning: (message: string) => toaster.show({ message, intent: 'warning', timeout: 0 }),
  }
}

export const snapAuth = new SDK(SDK_PUBLISHABLE_KEY, API_HOST)
