import {
  AnchorButton,
  HTMLSelect,
} from '@blueprintjs/core'
import {
  Switch,
  Route,
  useLocation,
} from 'wouter'

import { ActiveLink } from 'components'
import { useApiData } from 'hooks'

import ApiKeys from './ApiKeys'
import GettingStarted from './GettingStarted'
import Stats from './Stats'

import './index.css'

const Env: React.FC<{ envId: EnvId, orgId: OrgId }> = ({ envId, orgId }) => {
  const env = useApiData('/app/getEnvById', { envId })
  const envIds = useApiData('/app/getEnvironmentIdsForOrg', { orgId })
  const [path, setLocation] = useLocation()
  if (!env || !envIds) {
    return null
  }

  const onEnvChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newid = e.currentTarget.value
    setLocation('/../' + newid + path, {})
  }

  return (
    <div id="Env">
      <nav>
        <HTMLSelect onChange={onEnvChange} defaultValue={envId}>
          <optgroup label="Environments">
            {envIds.map(id => <EnvRow envId={id} key={id}/>)}
          </optgroup>
        </HTMLSelect>

        <br />
        <br />

        <code>{env.rpId}</code>

        <br />
        <br />

        <ul>
          <li><ActiveLink href="/" text="Domain Info" icon="globe-network" minimal /></li>
          <li><ActiveLink href="/keys" text="API Keys" icon="key" minimal /></li>
          <li><ActiveLink href="/browser-stats" text="Browser Stats" icon="application" minimal /></li>
        </ul>

        <br />

        {envIds.length < 3 &&
        <ActiveLink href={`~/org/${orgId}/createEnv`} icon="plus">Add Environment</ActiveLink>
        }

        <h2>Docs</h2>
        <AnchorButton href="https://www.snapauth.app/snapauth-web-integration-guide" target="_blank" text="Quick Start" minimal fill alignText="left" />
        <AnchorButton href="https://docs.snapauth.app" target="_blank" minimal fill alignText="left" text="Overview" />
        <AnchorButton href="https://docs.snapauth.app/client.html" target="_blank" minimal fill alignText="left" text="Client APIs" />
        <AnchorButton href="https://docs.snapauth.app/server.html" target="_blank" minimal fill alignText="left" text="Server APIs" />

      </nav>
      <article>
        <Switch>
          <Route path="/keys" component={() => <ApiKeys envId={envId} />} />
          <Route path="/browser-stats" component={() => <Stats envId={envId} />} />
          <Route component={() => <GettingStarted envId={envId} />} />
        </Switch>
      </article>
    </div>
  )

}

const EnvRow: React.FC<{ envId: EnvId }> = ({ envId }) => {
  const env = useApiData('/app/getEnvById', { envId })

  return <option value={envId}>{env?.name}</option>
}

export default Env
