import { useEffect, useState } from 'react'
import { Button, Callout, ControlGroup, FormGroup, H4, InputGroup, Radio, RadioGroup } from '@blueprintjs/core'
import { useLocation } from 'wouter'

import { useApp, useApiData } from 'hooks'
import { Layout } from 'pages'
import { toast } from 'utilities'

import './index.css'

// CAUTION: these values are used directly by the API!
enum MatchType {
  WildCard = 'wc',
  MultiOrigin = 'mo',
}

enum RpType {
  Registrable = 'rd',
  AsProvided = 'host',
}

const RdNotChecked = undefined
const RdChecking = null
const RdInvalid = false

type RdState =
  | typeof RdNotChecked
  | typeof RdChecking
  | typeof RdInvalid
  | string

interface Props {
  orgId: OrgId
}
const CreateEnv: React.FC<Props> = ({ orgId }) => {
  const org = useApiData('/app/getOrgById', { orgId })
  const [, setLocation] = useLocation()
  const app = useApp()

  // Remote result from Public Suffix List
  const [rd, setRd] = useState<RdState>(RdNotChecked)

  // User form input
  const [domain, setDomain] = useState('')
  // And hostname via basic parsing
  const [host, setHost] = useState('')

  // Radio selector for RP scope
  const [rpType, setRpType] = useState(RpType.Registrable)

  // Future scope/edit only: wildcard vs specific origins. Leaving the state
  // partially in place to ease adding a toggle later and have the data for the
  // creation API.
  const [matchType] = useState(MatchType.WildCard)

  // This merely translates the input field into a hostname
  useEffect(() => {
    // Clear remote-parsed version immedaitely
    setRd(undefined)
    // And reset to whole domain
    setRpType(RpType.Registrable)

    if (domain === '') {
      return
    }
    let url: URL
    try {
      url = new URL(domain)
    } catch (error) {
      url = new URL('https://' + domain)
    }
    setHost(url.hostname)
  }, [domain])

  let rpId: string
  if (rd === RdNotChecked || rd === RdChecking || rd === RdInvalid) {
    rpId = ''
  } else if (rpType === RpType.Registrable) {
    rpId = rd
  } else if (rpType === RpType.AsProvided) {
    rpId = host
  } else {
    throw new Error('Unhandled rpType')
  }

  if (!org) {
    return null
  }

  const validateRpId = async () => {
    setRd(RdNotChecked)
    const result = await app.post('/app/parseRpId', { uri: domain })
    if (result === undefined) {
      setRd(RdInvalid)
    } else {
      setRd(result.registrableDomain)
    }
  }

  const hostIsSubdomain = rd !== host // and valid!
  const disableValidate = domain === ''
  const disableForm = rpId === ''

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const fd = new FormData(e.target as HTMLFormElement)
    const name = fd.get('name')
    const displayName = fd.get('displayName')
    const data = {
      displayName,
      name,
      orgId,
      rpId,
      rpType: matchType,
    }

    const res = await app.post('/app/createEnv', data)
    if (res) {
      toast.success('Environment added!')
      setLocation(`~/org/${orgId}/env/${res.envId}`)
    } else {
      toast.warning('Could not add new environment.')
    }
  }

  return (
    <Layout.Dialog title={`Add Environment to ${org.name}`} id="CreateEnv">
      <form onSubmit={submit}>
        <FormGroup helperText={<p>Internal name for this environment.<br />Examples: staging, dev, prod, test, qa</p>} label="Name" >
          <InputGroup leftIcon="text-highlight" name="name" placeholder="Staging" required type="text" />
        </FormGroup>

        <FormGroup label="Display Name" helperText="Visible to end-users" >
          <InputGroup leftIcon="text-highlight" name="displayName" placeholder={`${org.name} (Staging)`} required type="text" />
        </FormGroup>

        <FormGroup helperText="Where will you integrate SnapAuth?" label="Domain" >
          <ControlGroup fill>
            <InputGroup leftIcon="globe" name="domain" placeholder="example.com" onBlur={validateRpId} required type="text" value={domain} onChange={(e) => setDomain(e.target.value)} intent={rd === RdInvalid ? 'danger' : 'none'} />
            <Button icon="spell-check" text="Validate" type="button" onClick={validateRpId} disabled={disableValidate} />
          </ControlGroup>
        </FormGroup>


        {/* Default to Yes/WC, let edits happen later if needed
        <FormGroup label="Include subdomains" helperText="This can be changed later">
          <RadioGroup selectedValue={matchType} onChange={(e) => setMatchType(e.currentTarget.value as MatchType)}>
            <Radio name="scope" label="Yes" value={MatchType.WildCard} required />
            <Radio name="scope" label="No" value={MatchType.MultiOrigin} />
          </RadioGroup>
        </FormGroup>
        */}

        {rpId !== '' && hostIsSubdomain && (
          // The var interpolation is a little sketchy here, but non-blank rpId implies rd is set
          <FormGroup label="Domain Matching">
            <RadioGroup selectedValue={rpType} onChange={(e) => setRpType(e.currentTarget.value as RpType)}>
              <Radio name="match" value={RpType.Registrable} label={`All of ${rd}`} required />
              <Radio name="match" value={RpType.AsProvided} label={`Only ${host}`} />
            </RadioGroup>

            {rpType === RpType.AsProvided && (
              <Callout intent="warning">
                <H4>Your <a href="https://www.w3.org/TR/webauthn-3/#relying-party-identifier" target="_blank" rel="noreferrer">RP ID</a> will be <code>{rpId}</code></H4>
                <p>You will be able to use SnapAuth on <code>{rpId}</code> and its subdomains, but <strong>not</strong> all of <code>{rd}</code>.</p>
                <p>User credentials are bound to the RP ID, so it <strong>cannot be changed later</strong>.</p>
              </Callout>
            )}
          </FormGroup>
        )}

        <Button intent="primary" type="submit" disabled={disableForm}>Create Environment</Button>
      </form>
    </Layout.Dialog>
  )
}

export default CreateEnv
