// Pull values from the runtime template at public/config.js
// (there's an `envsubst` trick that runs at deploy, .env works locally)
const INJECTED_ENV_DATA = window.ENV
const BUILDTIME_ENV_DATA = import.meta.env

// Follow the VITE_ prefix convention
const SANITIZED_INJECTED_ENV_DATA = Object.keys(INJECTED_ENV_DATA)
  .filter((key) => key === 'NODE_ENV' || key.startsWith('VITE_'))
  .reduce((obj, key) => {
    const value = INJECTED_ENV_DATA[key]
    // Remove blanks or non-substituted values
    if (value !== '' && !value.startsWith('$')) {
      obj[key] = value
    }
    return obj
  }, {} as typeof INJECTED_ENV_DATA)

const combined: DictOf<string> = {
  ...BUILDTIME_ENV_DATA,
  ...SANITIZED_INJECTED_ENV_DATA,
}

/******************************************************************************
 * IMPORTANT                                                                  *
 *                                                                            *
 * You must add a matching entry to public/config.js for any of this to work. *
 ******************************************************************************/

export const NODE_ENV = combined.NODE_ENV as 'production' | 'staging' | 'development'
export const API_HOST = combined.VITE_API_HOST || 'https://api.snapauth.app'
export const SDK_PUBLISHABLE_KEY = combined.VITE_SDK_PUBLISHABLE_KEY
